export enum PaymentMethods {
	sepa = 'sepa',
	paypal = 'paypal',
}

class OrderData {
	public firstname: string;
	public lastname: string;
	public phone: string;
	public email: string;
	public address: string;
	public location: string;
	public birth: string;
	public zip: string;
	public payment_method: PaymentMethods;
	public offer: string;
	public owner: string;
	public iban: string;
	public swift: string;
	public contract_start: Date;
	public training_start: Date;

	constructor(
		firstname: string,
		lastname: string,
		phone: string,
		email: string,
		address: string,
		location: string,
		birth: string,
		zip: string,
		payment_method: PaymentMethods,
		offer: string,
		owner: string,
		iban: string,
		swift: string,
		contract_start: Date,
		training_start: Date
	) {
		this.firstname = firstname;
		this.lastname = lastname;
		this.phone = phone;
		this.email = email;
		this.address = address;
		this.location = location;
		this.birth = birth;
		this.zip = zip;
		this.payment_method = payment_method;
		this.offer = offer;
		this.owner = owner;
		this.iban = iban;
		this.swift = swift;
		this.contract_start = contract_start;
		if (training_start !== null) {
			this.training_start = new Date(
				Date.UTC(training_start.getFullYear(), training_start.getMonth(), training_start.getDate())
			);
		} else {
			this.training_start = contract_start;
		}
	}

	public toJSON() {
		return {
			firstname: this.firstname,
			lastname: this.lastname,
			phone: this.phone,
			email: this.email,
			address: this.address,
			location: this.location,
			birth: this.birth,
			zip: this.zip,
			payment_method: this.payment_method,
			offer: this.offer,
			owner: this.owner,
			iban: this.iban,
			swift: this.swift,
			contract_start: this.contract_start,
			training_start: this.training_start,
		};
	}

	public static fromForm(maybe: any): OrderData {
		return new OrderData(
			maybe.firstname,
			maybe.lastname,
			maybe.phone,
			maybe.email,
			maybe.address,
			maybe.location,
			maybe.birth,
			maybe.zip,
			maybe.payment_method,
			maybe.offer,
			maybe.owner,
			maybe.iban,
			maybe.swift,
			maybe.contract_start,
			maybe.training_start
		);
	}
}

export default OrderData;
