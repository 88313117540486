import { FormControl, Grid, Input, makeStyles } from '@material-ui/core';
import React from 'react';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions, { extraNarrowWidth } from '../../../styles/dimensions';
import content from '../../../content/offer/address.json';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import CheckoutBreadcrumbs from '../../checkoutBreadcrumbs';
// @ts-ignore
import RedErrorIcon from '../../../images/offer/error_red_warning_icon.svg';
// @ts-ignore
import BackArrowIcon from '../../../images/offer/arrow_back_green.svg';

interface Props {
	step: number;
	changeStep: (value: number) => void;
	goBack: () => void;
	goForward: () => void;
	control: Control<Record<string, any>>;
	trigger: (name?: string | string[]) => Promise<boolean>;
	errors: DeepMap<Record<string, any>, FieldError>;
}

interface ClassProps {
	step: number;
}

const Address = (props: Props) => {
	const { step, changeStep, goBack, goForward, control, trigger, errors } = props;

	const classes = useClasses({ step });

	const handleNext = React.useCallback(() => {
		trigger(
			content.fields.map((fieldName) => {
				return fieldName.name;
			})
		).then((valid: boolean) => {
			if (valid) {
				goForward();
			}
		});
	}, [step]);

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<CheckoutBreadcrumbs step={step} changeStep={changeStep} />

				<h1 className={classes.header}>{content.title}</h1>

				<form onSubmit={(event) => event.preventDefault()}>
					<Grid container>
						{content.fields.map((item, key) => (
							<Grid key={key} item xs={12} sm={6} classes={{ root: classes.gridItem }}>
								<FormControl
									fullWidth
									style={key % 2 === 0 ? { paddingRight: '1em' } : { paddingLeft: '1em' }}
									classes={{ root: classes.formControlRoot }}>
									<Controller
										as={<Input />}
										defaultValue=""
										control={control}
										name={item.name}
										placeholder={item.placeholder}
										type={item.type}
										rules={{ required: item.fieldError }}
										classes={{
											root: classes.input,
											input: classes.input,
											underline: classes.inputUnderline,
										}}
									/>
								</FormControl>
								<div
									className={classes.errorContainer}
									style={
										key % 2 === 0
											? {
													paddingRight: '1.3333333333333333em',
													visibility: errors[item.name] ? 'visible' : 'hidden',
											  }
											: {
													paddingLeft: '1.3333333333333333em',
													visibility: errors[item.name] ? 'visible' : 'hidden',
											  }
									}>
									<img src={RedErrorIcon} alt="" loading="lazy" />
									<span className={classes.errorSpan}>{item.fieldError}</span>
								</div>
							</Grid>
						))}
					</Grid>
					<div className={classes.formNavigationContainer}>
						<div className={classes.backContainer}>
							<img src={BackArrowIcon} alt="" loading="lazy" />
							<span className={classes.backSpan} onClick={goBack}>
								{content.back}
							</span>
						</div>
						<button className={classes.button} onClick={handleNext}>
							{content.continue}
						</button>
					</div>
				</form>
			</div>
		</section>
	);
};

export default Address;

const useClasses = makeStyles({
	section: (props: ClassProps) => ({
		display: props.step === 2 ? 'block' : 'none',
		paddingTop: '2.25em',
		paddingBottom: '2.75em',
		backgroundColor: Colors.offerBackgroundBlack,
		minHeight: 'calc(100vh - 5.25em)',
		boxSizing: 'border-box',
		'& .MuiInput-root:hover::before': {
			borderColor: Colors.whiteLightlyDimmed,
		},
		'@media (max-width: 631px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	}),
	container: {
		maxWidth: extraNarrowWidth,
		margin: '0 auto',
	},
	gridItem: {
		marginBottom: '2.75em',
	},
	formControlRoot: {
		boxSizing: 'border-box',
		'@media (max-width: 599px)': {
			padding: '0 !important',
		},
	},
	input: {
		fontFamily: FiraSans.book,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.whiteLightlyDimmed,
		'&::placeholder': {
			opacity: 0.7,
		},
	},
	inputUnderline: {
		'&:after, &:before': {
			borderBottomColor: Colors.dividerGray,
		},
	},
	header: {
		color: Colors.white,
		marginTop: '0.9em',
		marginBottom: '1.3em',
		textAlign: 'center',
		'@media (max-width: 959px)': {
			marginBottom: '0.9em',
		},
	},
	checkout: {
		color: Colors.whiteDimmed,
		textAlign: 'center',
		letterSpacing: '0.125em',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		marginTop: 0,
		marginBottom: '1.3333333333333333em',
	},
	formNavigationContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '0.625em',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	},
	backContainer: {
		cursor: 'pointer',
	},
	backSpan: {
		color: Colors.limeGreen,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		marginLeft: '0.25em',
	},
	button: {
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		border: 0,
		borderRadius: '8px',
		outline: 0,
		cursor: 'pointer',
		padding: '0.875em 2.25em',
		'@media (max-width: 599px)': {
			display: 'block',
			width: '100%',
			marginTop: '2em',
		},
	},
	errorContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		color: Colors.errorRed,
		position: 'relative',
		top: '1.5625em',
		marginTop: '-1.0625em',
	},
	errorSpan: {
		marginLeft: '0.6666666666666666em',
	},
});
