import {
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	Grid,
	Input,
	makeStyles,
	Snackbar,
} from '@material-ui/core';
import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import content from '../../../content/offer/payment.json';
import billContent from '../../../content/offer/bill.json';
import Colors from '../../../styles/colors';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Dimensions, { extraNarrowWidth } from '../../../styles/dimensions';
import { OfferData } from '../../../pages/angebot';
import { PayPalButton } from 'react-paypal-button-v2';
import CheckboxMarked from '../../../images/offer/checkboxMarked';
import CheckboxNotMarked from '../../../images/offer/checkboxNotMarked';
import CheckoutBreadcrumbs from '../../checkoutBreadcrumbs';
import service from '../../../service/service';
import OrderData, { PaymentMethods } from '../../../types/orderData';
import { Alert } from '@material-ui/lab';
import gtagReport from '../../../helpers/gtagReport';
// @ts-ignore
import RedErrorIcon from '../../../images/offer/error_red_warning_icon.svg';
// @ts-ignore
import BackArrowIcon from '../../../images/offer/arrow_back_green.svg';

interface Props {
	offer: OfferData;
	step: number;
	changeStep: (value: number) => void;
	goBack: () => void;
	goForward: () => void;
	control: Control<Record<string, any>>;
	errors: DeepMap<Record<string, any>, FieldError>;
	handleSubmit: any;
	getValues: (value?: string | string[]) => any;
	trigger: (name?: string | string[]) => Promise<boolean>;
	watch: (value?: string | string[]) => any;
}

interface ClassProps {
	step: number;
	loading: boolean;
}

const fieldRegex = {
	owner: /(\w.+\s).+/,
	iban: /([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?/,
	swift: /[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?/,
};

const Payment = (props: Props) => {
	const { offer, step, changeStep, goBack, goForward, control, errors, handleSubmit, getValues, trigger, watch } =
		props;

	const [loading, setLoading] = React.useState(false);
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);

	const acceptValue = watch(content.accept.name);

	const classes = useClasses({ step, loading });

	const handleCreatePaypalOrder = React.useCallback(
		(data, actions) => {
			return actions.order.create({
				purchase_units: [
					{
						description: 'Easy12 - Einmalzahlung',
						amount: {
							currency_code: 'EUR',
							value: 497.6,
							breakdown: {
								item_total: { currency_code: 'EUR', value: 403.05 },
								tax_total: { currency_code: 'EUR', value: 94.55 },
							},
						},
					},
				],
				application_context: {
					shipping_preference: 'NO_SHIPPING',
				},
				intent: 'CAPTURE',
			});
		},
		[offer]
	);

	const handlePaypalOrderApprove = React.useCallback(
		async (data, actions) => {
			const capture = actions.order.capture();

			setLoading(true);

			const values = getValues();

			if (typeof window !== 'undefined') {
				gtagReport(window.location.pathname);
			}

			await service
				.orderSubmitted(
					OrderData.fromForm({
						...values,
						offer: `${offer.name} - ${offer.group}`,
						payment_method: PaymentMethods.paypal,
					})
				)
				.finally(() => {
					goForward();
					setLoading(false);
				});

			return capture;
		},
		[offer, step]
	);

	const paypalInterceptor = React.useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
			trigger(content.accept.name);
			if (!acceptValue) {
				event.stopPropagation();
			}
		},
		[acceptValue]
	);

	const handleError = React.useCallback(() => {
		setSnackbarOpen(true);
	}, []);

	const handleSnackbarClose = React.useCallback(() => {
		setSnackbarOpen(false);
	}, []);

	const checkout = React.useCallback(
		(values) => {
			let offerField = '';
			if (offer.group !== '/Einmalig' && (values.mega || offer.megaIncluded)) {
				offerField = ' Mega';
			} else if (offer.group !== '/Einmalig') {
				billContent.optionals.forEach((item) => {
					if (values[item.name] === true) {
						offerField = ` ${item.label}`;
					}
				});
			}

			let orderFromForm: OrderData;
			try {
				orderFromForm = OrderData.fromForm({
					...values,
					offer: `${offer.name}${offerField} - ${offer.group}`,
					payment_method: PaymentMethods.sepa,
				});
			} catch (error) {
				handleError();
				return;
			}

			setLoading(true);

			if (typeof window !== 'undefined') {
				gtagReport(window.location.pathname);
			}

			service
				.orderSubmitted(orderFromForm)
				.then(() => {
					setLoading(false);
					goForward();
				})
				.catch(() => {
					setLoading(false);
					handleError();
				});
		},
		[offer, step]
	);

	React.useEffect(() => {
		if (typeof document !== 'undefined' && step === 4 && offer.group === '/Einmalig') {
			const paypalContainer = document.getElementsByClassName(classes.paypalButtonContainer)[0];
			if (!acceptValue) {
				// @ts-ignore
				paypalContainer.firstElementChild.style.cursor = 'pointer';
				// @ts-ignore
				paypalContainer.firstElementChild.firstElementChild.style.pointerEvents = 'none';
			}
		}
	}, [step, acceptValue]);

	React.useEffect(() => {
		if (typeof document !== 'undefined' && offer.group === '/Einmalig') {
			if (acceptValue) {
				trigger(content.accept.name);
			}

			const paypalContainer = document.getElementsByClassName(classes.paypalButtonContainer)[0];

			if (paypalContainer?.firstElementChild?.firstElementChild === undefined) {
				return;
			}

			if (!acceptValue) {
				// @ts-ignore
				paypalContainer.firstElementChild.firstElementChild.style.pointerEvents = 'none';
			} else {
				// @ts-ignore
				paypalContainer.firstElementChild.firstElementChild.style.pointerEvents = 'auto';
			}
		}
	}, [acceptValue]);

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<CheckoutBreadcrumbs step={step} changeStep={changeStep} />

				<h1 className={classes.header}>{content.title}</h1>

				<h4 className={classes.subheader}>{content.paymentMethodTitle}</h4>

				<h6 className={classes.paymentMethod}>{content.directDebit.title}</h6>
				<p className={classes.sepaDescription}>{content.directDebit.description}</p>

				<form onSubmit={handleSubmit(checkout)}>
					{content.fields.map((item, key) => (
						<Grid key={key} item xs={12}>
							<FormControl fullWidth classes={{ root: classes.formControlRoot }}>
								<Controller
									as={<Input />}
									defaultValue=""
									control={control}
									name={item.name}
									placeholder={item.placeholder}
									type={item.type}
									rules={
										item.name !== 'swift'
											? {
													pattern: {
														value: fieldRegex[item.name],
														message: item.patternError,
													},
											  }
											: {
													required: item.fieldError,
													pattern: {
														value: fieldRegex[item.name],
														message: item.patternError,
													},
											  }
									}
									classes={{
										root: classes.input,
										input: classes.input,
										underline: classes.inputUnderline,
									}}
								/>
							</FormControl>
							<div
								className={classes.errorContainer}
								style={{ visibility: errors[item.name] ? 'visible' : 'hidden' }}>
								<img src={RedErrorIcon} alt="" loading="lazy" />
								<span className={classes.errorSpan}>{errors[item.name]?.message}</span>
							</div>
						</Grid>
					))}

					{step === 4 && offer.group === '/Einmalig' && (
						<>
							<h6 className={classes.paymentMethod}>{content.paypalTitle}</h6>

							<div className={classes.paypalButtonContainer} onClick={paypalInterceptor}>
								<PayPalButton
									shippingPreference="NO_SHIPPING"
									currency="EUR"
									createOrder={handleCreatePaypalOrder}
									onApprove={handlePaypalOrderApprove}
									catchError={handleError}
								/>
							</div>
						</>
					)}

					<Grid container classes={{ root: classes.contactGridRoot }}>
						<Grid item xs={12}>
							<h6 className={classes.contactHeader}>{content.contact.title}</h6>
						</Grid>
						<Grid item xs={12} sm={6}>
							<p className={classes.contactItemHeader}>{content.contact.address.title}</p>
							<p className={classes.contactItemDescription}>{content.contact.address.description}</p>
						</Grid>
						<Grid item xs={12} sm={6}>
							<div>
								<p className={classes.contactItemHeader}>{content.contact.email.title}</p>
								<p className={classes.contactItemDescription}>{content.contact.email.description}</p>
							</div>

							<div>
								<p className={classes.contactItemHeader}>{content.contact.director.title}</p>
								<p className={classes.contactItemDescription} style={{ marginBottom: 0 }}>
									{content.contact.director.description}
								</p>
							</div>
						</Grid>
					</Grid>

					<FormControl fullWidth>
						<Controller
							render={({ value, name, onChange }) => (
								<FormControlLabel
									control={
										<Checkbox
											color="default"
											checked={value}
											name={name}
											onChange={(e) => onChange(e.target.checked)}
											icon={<CheckboxNotMarked />}
											checkedIcon={<CheckboxMarked />}
										/>
									}
									defaultChecked={false}
									label={
										<span>
											{content.accept.labelPart1}
											<a
												href={content.accept.labelLink.href}
												target="_blank"
												className={classes.labelLink}>
												{content.accept.labelLink.text}
											</a>
											{content.accept.labelPart2}
										</span>
									}
									classes={{ label: classes.checkboxLabel, root: classes.checkboxLabelRoot }}
								/>
							)}
							control={control}
							name={content.accept.name}
							defaultValue={false}
							rules={{ required: content.accept.fieldError }}
						/>
					</FormControl>
					<div
						className={classes.errorContainer}
						style={{ visibility: errors[content.accept.name] ? 'visible' : 'hidden', top: '-2.25em' }}>
						<img src={RedErrorIcon} alt="" loading="lazy" />
						<span className={classes.errorSpan}>{content.accept.fieldError}</span>
					</div>

					<div className={classes.formNavigationContainer}>
						<div className={classes.backContainer}>
							<img src={BackArrowIcon} alt="" loading="lazy" />
							<span className={classes.backSpan} onClick={goBack}>
								{content.back}
							</span>
						</div>
						<div className={classes.buttonInnerGroup}>
							{loading && <CircularProgress classes={{ circle: classes.spinnerCircle }} />}
							<button
								type="submit"
								className={classes.button}
								onClick={handleSubmit(checkout)}
								disabled={loading}>
								{content.register}
							</button>
						</div>
					</div>
				</form>
			</div>
			<Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
				<Alert
					onClose={handleSnackbarClose}
					severity="error"
					variant="filled"
					classes={{ message: classes.snackbarAlert }}>
					{content.submitError}
				</Alert>
			</Snackbar>
		</section>
	);
};

export default Payment;

const useClasses = makeStyles({
	section: (props: ClassProps) => ({
		display: props.step === 4 ? 'block' : 'none',
		paddingTop: '2.25em',
		paddingBottom: '2.75em',
		backgroundColor: Colors.offerBackgroundBlack,
		minHeight: 'calc(100vh - 5.25em)',
		boxSizing: 'border-box',
		'& .MuiInput-root:hover::before': {
			borderColor: Colors.whiteLightlyDimmed,
		},
		'@media (max-width: 631px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	}),
	container: {
		maxWidth: extraNarrowWidth,
		margin: '0 auto',
	},
	header: {
		color: Colors.white,
		marginTop: '0.9em',
		marginBottom: '1.3em',
		textAlign: 'center',
		'@media (max-width: 959px)': {
			marginBottom: '0.9em',
		},
	},
	contactGridRoot: {
		backgroundColor: Colors.paymentContactGray,
		borderRadius: '8px',
		padding: '1.75em',
		marginTop: '2em',
		marginBottom: '1.75em',
		'@media (max-width: 599px)': {
			padding: '1.25em 0.75em 1.5em',
			marginBottom: '2em',
		},
	},
	contactHeader: {
		color: Colors.white,
		marginTop: 0,
		marginBottom: '1em',
		'@media (max-width: 599px)': {
			marginBottom: '1.25em',
			whiteSpace: 'pre-wrap',
		},
	},
	contactItemHeader: {
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.tinyText.fontSize,
		lineHeight: Dimensions.tinyText.lineHeight,
		margin: 0,
	},
	contactItemDescription: {
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.tinyText.fontSize,
		lineHeight: Dimensions.tinyText.lineHeight,
		margin: 0,
		whiteSpace: 'pre-wrap',
		marginBottom: '1em',
	},
	formControlRoot: {
		boxSizing: 'border-box',
		marginBottom: '2.5em',
	},
	input: {
		fontFamily: FiraSans.book,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.whiteLightlyDimmed,
		'&::placeholder': {
			opacity: 0.7,
			color: Colors.white,
		},
	},
	inputUnderline: {
		'&:after, &:before': {
			borderBottomColor: Colors.dividerGray,
		},
	},
	ibanContainer: {
		height: '1.5em',
		marginBottom: '2.5em',
		'& *': {
			height: '1.5em',
		},
	},
	backContainer: {
		cursor: 'pointer',
	},
	backSpan: {
		color: Colors.limeGreen,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		marginLeft: '0.25em',
	},
	button: {
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		border: 0,
		borderRadius: '8px',
		outline: 0,
		cursor: 'pointer',
		padding: '0.875em 2.25em',
		marginLeft: (props: ClassProps) => (props.loading ? '1em' : 0),
		'@media (max-width: 599px)': {
			display: 'block',
			width: (props: ClassProps) => (props.loading ? 'initial' : '100%'),
		},
	},
	buttonInnerGroup: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		'@media (max-width: 599px)': {
			width: '100%',
			marginTop: '2em',
		},
	},
	spinnerCircle: {
		stroke: Colors.limeGreen,
	},
	errorContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.extraTinyText.fontSize,
		lineHeight: Dimensions.extraTinyText.lineHeight,
		color: Colors.errorRed,
		position: 'relative',
		top: '-1.5625em',
		marginTop: '-1.0625em',
	},
	errorSpan: {
		marginLeft: '0.6666666666666666em',
	},
	subheader: {
		color: Colors.white,
		marginTop: '1.5em',
		marginBottom: '1em',
	},
	paymentMethod: {
		color: Colors.white,
		marginTop: 0,
		marginBottom: '0.625em',
	},
	sepaDescription: {
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		marginTop: '0.625em',
		marginBottom: '2em',
		'@media (min-width: 600px)': {
			maxWidth: 'calc(100% * 4 / 6)',
		},
	},
	paypalButtonContainer: {
		border: `1px solid ${Colors.paypalBorderGray}`,
		padding: '1em',
		borderRadius: '8px',
	},
	checkboxLabelRoot: {
		marginBottom: '3.25em',
		alignItems: 'baseline',
		'@media (min-width: 600px)': {
			maxWidth: '80%',
		},
	},
	checkboxLabel: {
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.tinyText.fontSize,
		lineHeight: Dimensions.tinyText.lineHeight,
	},
	formNavigationContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
			alignItems: 'flex-start',
			marginTop: '2.75em',
		},
	},
	snackbarAlert: {
		fontFamily: FiraSans.book,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
	},
	labelLink: {
		textDecoration: 'underline',
	},
});
