import { makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';
import content from '../../../content/offer/feedback.json';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Colors from '../../../styles/colors';
import Dimensions, { defaultWidth } from '../../../styles/dimensions';
// @ts-ignore
import LogoImg from '../../../images/Easyfit_logo.svg';
// @ts-ignore
import backgroundImage from '../../../images/offer/thank_you.png';
// @ts-ignore
import backgroundImageTablet from '../../../images/offer/thank_you_tablet.png';

interface Props {
	step: number;
}

const Feedback = (props: Props) => {
	const { step } = props;

	const classes = useClasses({ step });

	React.useEffect(() => {
		if (typeof window !== 'undefined' && step === 5) {
			document.getElementById('___gatsby').scroll({ top: 0, left: 0, behavior: 'auto' });
		}
	}, [step]);

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<div className={classes.innerContainer}>
					<div className={classes.contentContainer}>
						<img src={LogoImg} alt={content.logo.alt} className={classes.logo} loading="lazy" />
						<h2 className={classes.header}>{content.title}</h2>
						<Link to={content.button.src}>
							<div className={classes.offerContainer}>
								<span className={classes.offerText}>{content.button.text}</span>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Feedback;

const useClasses = makeStyles({
	section: (props: Props) => ({
		display: props.step === 5 ? 'block' : 'none',
		backgroundColor: Colors.backgroundDarkLimeGreen,
	}),
	container: {
		backgroundImage: `url(${backgroundImage})`,
		backgroundPositionX: '100%',
		backgroundPositionY: '5%',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		'@media (max-width: 1231px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
		'@media (max-width: 959px)': {
			backgroundImage: `url(${backgroundImageTablet})`,
			backgroundPositionY: 'center',
		},
	},
	innerContainer: {
		maxWidth: defaultWidth,
		margin: '0 auto',
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '100vh',
		'@media (min-width: 600px)': {
			maxWidth: '50%',
		},
	},
	header: {
		whiteSpace: 'pre-wrap',
		color: Colors.white,
		textAlign: 'center',
		marginTop: 0,
		marginBottom: '1.1em',
		'@media (max-width: 959px)': {
			marginTop: '0.9em',
			marginBottom: '1em',
		},
		'@media (max-width: 599px)': {
			fontSize: Dimensions.largeText.fontSize,
			lineHeight: Dimensions.largeText.lineHeight,
			marginTop: '1.5em',
			marginBottom: '1.6666666666666667em',
		},
	},
	offerContainer: {
		backgroundColor: Colors.limeGreen,
		borderRadius: '8px',
		padding: '0.875em 2.25em',
	},
	offerText: {
		fontFamily: FiraSans.semiBold,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		color: Colors.headerButtonBlack,
	},
	logo: {
		maxWidth: '216px',
		'@media (min-width: 960px)': {
			display: 'none',
		},
	},
});
