import React from 'react';
import { useForm } from 'react-hook-form';
import Layout from '../components/layout';
import Address from '../components/sections/offer/address';
import Bill from '../components/sections/offer/bill';
import Contracts from '../components/sections/offer/contracts';
import Feedback from '../components/sections/offer/feedback';
import Introduction from '../components/sections/offer/introduction';
import Payment from '../components/sections/offer/payment';
import SEO from '../components/seo';
import Colors from '../styles/colors';
import { extraNarrowWidth } from '../styles/dimensions';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { GoogleReviewsResponse } from './index';

export interface OfferData {
	name: string;
	price: number;
	priceSubtext?: string;
	startupFee: number;
	group: string;
	megaIncluded: boolean;
}

interface Props {
	data: GoogleReviewsResponse;
	[key: string]: any;
}

export const googleReviewsQuery = graphql`
	query GoogleReviewsOffer {
		allGoogleReview {
			nodes {
				body
				source
				sourceImage
				date
			}
		}
	}
`;

const Offer = ({ data }: Props) => {
	const [step, setStep] = React.useState(0);
	const [offer, setOffer] = React.useState<OfferData>({
		name: '',
		price: null,
		priceSubtext: undefined,
		startupFee: null,
		group: '',
		megaIncluded: false,
	});

	const { control, handleSubmit, errors, trigger, clearErrors, getValues, watch, setValue } = useForm();

	const handleOfferChange = React.useCallback((value: OfferData) => {
		setOffer(value);
		setStep(1);
	}, []);

	const changeStep = React.useCallback(
		(value: number) => {
			if (typeof document !== 'undefined' && step === 4) {
				document.querySelector('script[src*=paypal]')?.remove();
			}
			setStep(value);
		},
		[step]
	);

	const goBack = React.useCallback(() => {
		if (typeof document !== 'undefined' && step === 4) {
			document.querySelector('script[src*=paypal]')?.remove();
		}
		clearErrors();
		setStep(step - 1);
	}, [step]);

	const goForward = React.useCallback(() => {
		setStep(step + 1);
	}, [step]);

	React.useEffect(() => {
		const header = document.querySelector('header');
		const main = document.querySelector('main');
		const footer = document.querySelector('footer');
		const middots = footer.firstElementChild.lastElementChild.getElementsByClassName('middot');

		const floatingButton = main.lastElementChild;
		// @ts-ignore
		floatingButton.style.display = 'none';
		if (step !== 0) {
			header.style.display = 'none';
			main.style.paddingTop = '0';
			footer.style.backgroundColor = Colors.offerBackgroundBlack;
			// @ts-ignore
			footer.firstElementChild.style.maxWidth = extraNarrowWidth;
			// @ts-ignore
			footer.firstElementChild.style.borderTop = `1px solid ${Colors.offerFooterBorderGray}`;
			// @ts-ignore
			footer.firstElementChild.style.height = 'calc(100% - 1px)';
			// @ts-ignore
			footer.firstElementChild.style.verticalAlign = 'middle';
			// @ts-ignore
			footer.firstElementChild.firstElementChild.style.display = 'none';
			// @ts-ignore
			footer.firstElementChild.lastElementChild.style.margin = '0 auto';

			if (window.innerWidth < 600) {
				// @ts-ignore
				(footer.firstElementChild.style.margin = '0 1em'),
					// @ts-ignore
					(footer.firstElementChild.lastElementChild.style.transform = 'translateY(1em)'),
					// @ts-ignore
					(footer.firstElementChild.lastElementChild.style.width = 'auto');
				// @ts-ignore
				footer.firstElementChild.lastElementChild.style.flexWrap = 'initial';

				// @ts-ignore
				middots[0].style.display = 'inline';
				// @ts-ignore
				middots[1].style.display = 'inline';
			}

			if (step === 5) {
				footer.style.backgroundColor = Colors.backgroundDarkLimeGreen;
				// @ts-ignore
				footer.firstElementChild.style.borderTop = '0';
				footer.style.borderTop = `1px solid ${Colors.feedbackLightDivider}`;
				// @ts-ignore
				footer.firstElementChild.lastElementChild.style.transform = 'translateY(0em)';
			}
		} else {
			header.removeAttribute('style');
			main.removeAttribute('style');
			footer.removeAttribute('style');
			footer.firstElementChild.removeAttribute('style');
			footer.firstElementChild.firstElementChild.removeAttribute('style');
			footer.firstElementChild.lastElementChild.removeAttribute('style');

			middots[0].removeAttribute('style');
			middots[1].removeAttribute('style');
		}
		return () => {
			header.removeAttribute('style');
			main.removeAttribute('style');
			footer.removeAttribute('style');
			footer.firstElementChild.removeAttribute('style');
			footer.firstElementChild.firstElementChild.removeAttribute('style');
			footer.firstElementChild.lastElementChild.removeAttribute('style');

			middots[0].removeAttribute('style');
			middots[1].removeAttribute('style');

			floatingButton.removeAttribute('style');
		};
	}, [step]);

	return (
		<Layout>
			<SEO title="Angebot" />

			<Helmet>
				{step > 0 && offer.group === '/Einmalig' && (
					<script
						src={`https://www.paypal.com/sdk/js?client-id=${process.env.GATSBY_PAYPAL_CLIENT_ID}&vault=false&intent=capture&currency=EUR&locale=de_DE&disable-funding=sepa,giropay,sofort,card`}></script>
				)}
			</Helmet>

			<Contracts step={step} handleOfferChange={handleOfferChange} googleReviews={data.allGoogleReview.nodes} />
			<Introduction
				step={step}
				changeStep={changeStep}
				goBack={goBack}
				goForward={goForward}
				control={control}
				trigger={trigger}
				errors={errors}
				getValues={getValues}
			/>
			<Address
				step={step}
				changeStep={changeStep}
				goBack={goBack}
				goForward={goForward}
				control={control}
				trigger={trigger}
				errors={errors}
			/>
			<Bill
				offer={offer}
				step={step}
				changeStep={changeStep}
				goBack={goBack}
				goForward={goForward}
				control={control}
				watch={watch}
				trigger={trigger}
				errors={errors}
				setValue={setValue}
			/>
			<Payment
				offer={offer}
				step={step}
				changeStep={changeStep}
				goBack={goBack}
				goForward={goForward}
				control={control}
				errors={errors}
				handleSubmit={handleSubmit}
				getValues={getValues}
				trigger={trigger}
				watch={watch}
			/>
			<Feedback step={step} />
		</Layout>
	);
};

export default Offer;
