class PreSubmitData {
	public firstname: string;
	public lastname: string;
	public phone: string;
	public email: string;

	constructor(firstname: string, lastname: string, phone: string, email: string) {
		this.firstname = firstname;
		this.lastname = lastname;
		this.phone = phone;
		this.email = email;
	}

	public toJSON() {
		return {
			firstname: this.firstname,
			lastname: this.lastname,
			phone: this.phone,
			email: this.email,
		};
	}

	public static fromForm(maybe: any): PreSubmitData {
		return new PreSubmitData(maybe.firstname, maybe.lastname, maybe.phone, maybe.email);
	}
}

export default PreSubmitData;
